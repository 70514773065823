@import url('https://fonts.googleapis.com/css?family=Roboto');

.text {
    font-family: 'Roboto', regular;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.center_text {
    text-align: center;
}

.input_field{
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    box-sizing: border-box;
    background-color: #F9F9FA;
    padding: 12px 20px;
    color: black;
    border: none;
    border-radius: 10px;
    width: 100%;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.05);
}

.icon {
    width: 4em;
    height: 4em;
}

:root{
    --button_color: #2EA043;
    --button_color_text: white;
}

#root {
    white-space: pre-line;
}

.submit {
    background-color: var(--button_color);
    color: var(--button_color_text);
    cursor: pointer;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease 0s;
}

.two_fields {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.column {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.grow { 
    transition: all .2s ease-in-out; 
    }
    
.grow:hover { 
    transform: scale(1.02); 
}

input[type=file]::file-selector-button {
    margin-right: 20px;
    border: none;
    background: var(--button_color);
    padding: 12px 20px;
    color: var(--button_color_text);
    cursor: pointer;
    border: none;
    border-radius: 10px;
    transition: all .2s ease-in-out; 
  }
  
  input[type=file]::file-selector-button:hover {
    transform: scale(1.05); 
  }

  .container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-content: center;
  }
  